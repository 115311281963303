<template>
	<div>
		<base-table
			table-top
			paginate
			:items="indicators"
			:headers="headers"
			row-key="project"
			excel-file-name="indicator"
			server-excel-export-url="/indicators/export-excel"
		>
			<template #action>
				<b-button variant="primary" :to="{ name: 'm&e.indicators.new' }">New Indicator</b-button>
			</template>

			<template #cell(actions)="{ item }">
				<b-button variant="outline-secondary" size="sm" @click="openIndicator(item)">View</b-button>
				<b-button
					v-b-tooltip.hover.top="'Edit'"
					variant="outline-secondary"
					class="btn-icon cursor-pointer ml-50"
					size="sm"
					@click="editIndicator(item)"
				>
					<feather-icon icon="Edit3Icon" size="16" />
				</b-button>
			</template>
		</base-table>

		<b-modal v-model="showDetails" size="lg" centered title="Project indicators" hide-footer lazy>
			<b-list-group v-if="!loading" flush>
				<template v-for="(key, i) in keys">
					<b-list-group-item :key="i" :data-key="i">
						<b-row class="align-items-center">
							<b-col>
								<div>{{ key === 'quantity' ? `${startCase(key)} (${current.unit})` : startCase(key) }}</div>
							</b-col>
							<b-col>
								<div class="">
									<div class="d-flex justify-content-between mb-50">
										<div class="mr-50">
											<span class="value-label">Current</span>
											<span class="font-weight-bold"> {{ current[key].current }}</span>
										</div>
										<span>{{ calcProgress(current[key]) }}%</span>
										<div class="ml-50">
											<span class="value-label">Target</span>
											<span class="font-weight-bold"> {{ current[key].target }}</span>
										</div>
									</div>
									<b-progress :value="calcProgress(current[key])" max="100" />
								</div>
							</b-col>
						</b-row>
					</b-list-group-item>
				</template>
			</b-list-group>
			<div v-else class="">
				<div class="text-center text-primary mt-3 mb-2">
					<b-spinner class="align-middle" style="width: 3rem; height: 3rem;"></b-spinner>
					<span class="d-block mt-1">Loading...</span>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import startCase from 'lodash.startcase';
import { BButton, BCol, BRow, BSpinner, BProgress, BListGroup, BListGroupItem, BModal, VBTooltip } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import store from '@/store';
import api from '@/utils/api';

export default {
	name: 'Indicators',

	components: { BButton, BCol, BRow, BSpinner, BProgress, BListGroup, BListGroupItem, BModal, BaseTable },

	directives: {
		'b-tooltip': VBTooltip,
	},

	data: () => ({
		indicators: [],
		showDetails: false,
		loading: false,
		current: undefined,

		headers: [
			{ key: 'project_id', label: 'Project code', sortable: true },
			{ key: 'unit', label: 'Project unit', sortable: true },
			{ key: 'contract_date.date', label: 'Contract date', sortable: true },
			{ key: 'announce_date.date', label: 'Announce date', sortable: true },
			{ key: 'end_date.date', label: 'End date', sortable: true },
			{ key: 'actions' },
		],
	}),

	computed: {
		keys() {
			const keysToExeclude = ['announce_date', 'end_date', 'unit', 'contract_date', 'project_id', 'id'];
			return this.current ? Object.keys(this.current).filter((key) => !keysToExeclude.includes(key)) : [];
		},
	},

	async created() {
		const data = await store.dispatch('indicators/getIndicators');
		this.indicators = data;
	},

	methods: {
		startCase,

		async openIndicator(data) {
			this.current = data;
			this.showDetails = true;
			this.loading = true;

			const { data: values } = await api.call({ path: `m&e/indicators/${data.project_id}`, method: 'GET' });

			Object.keys(values).forEach((key) => {
				this.current[key].current = values[key];
			});

			this.loading = false;
		},

		editIndicator(data) {
			this.$router.push({
				name: 'm&e.indicators.edit',
				params: {
					id: data.id,
				},
			});
		},

		calcProgress(item) {
			if (item.target === 0) return 0;
			return ((item.current / item.target) * 100).toFixed(0);
		},
	},
};
</script>

<style lang="scss" scoped>
.value-label {
	font-size: 12px;
	text-transform: uppercase;
	margin-right: 4px;
}
</style>
